import './Map.css'

function Map() {
    return (
        <section id="map">
            <div className='section-content full'>
                <iframe
                    title="google-map"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.3337439896463!2d16.9936687!3d51.12075170000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716a1ede6698f83%3A0x41535025262357aa!2sPlac%C3%B3wka%20stacjonarna%20mFinanse!5e0!3m2!1spl!2spl!4v1729789169992!5m2!1spl!2spl">
                </iframe>
            </div>
        </section>
    );
  }
  
  export default Map;
  